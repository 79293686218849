import React from 'react';
import './SmallContact.css';

function SmallContact() {
    return <div className="top-contact">
        <p className="phone-number">
    <img className="small-contact" src="https://res.cloudinary.com/dnk61xxgl/image/upload/v1618307794/phone-call_hfb6zr.png"/> +48 728484794
    </p>
    </div>
  }

export default SmallContact;